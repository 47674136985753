import { defineStore } from 'pinia';
// import useAuthStore from './authStore';
import UserService from '../services/UserService';

// const authStore = useAuthStore();
const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    status: 'Start',
    loading: false,
    newInvestment: null,
  }),
  actions: {
    async generateReceipt(data) {
      return UserService.generateReceipt(data);
    },
    async cancelReceipt(data) {
      return UserService.cancelReceipt(data);
    },
  },
});

export default useUserStore;
