import apiAuth from './api/apiAuth';

class UserService {
  static async createOrder(name, email, phone, planDonation) {
    const res = await apiAuth.post('/todoinclusion/order/create', {
      name, email, phone, planDonation,
    });
    return res.data;
  }

  static async saveOrder(paymentData) {
    const res = await apiAuth.post('/todoinclusion/order/save', { ...paymentData });
    return res.data;
  }

  static async generateReceipt(data) {
    const res = await apiAuth.post('/todoinclusion/receipt', { ...data });
    return res.data;
  }

  static async cancelReceipt(data) {
    const res = await apiAuth.post('/todoinclusion/receipt/cancel', { ...data });
    return res.data;
  }
}

export default UserService;
