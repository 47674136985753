<template>
  <router-view />
</template>

<script>

export default {
  components: {
  },
};
</script>

<style lang="scss" src="@/scss/app.scss">

</style>
