<template>
  <iframe src='/assets/files/terminosycondicionesTI.pdf' width="100%"></iframe>
</template>

<style>
body{
  height: 100vh;
}

iframe, #app{
  height: 100%;
}
</style>
