import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import ReceiptForm from '../views/ReceiptForm.vue';
import CancelSuscription from '../views/CancelSuscriptionForm.vue';
import Legal from '../views/Legal.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/receipt-form',
    name: 'ReceiptForm',
    component: ReceiptForm,
  },
  {
    path: '/cancel-suscription',
    name: 'CancelSuscription',
    component: CancelSuscription,
  },
  {
    path: '/terminos',
    name: 'Legal',
    component: Legal,
  },
  /* {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
  }, */
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
